import Button from "@mui/material/Button";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";
import { Divider } from "@mui/material";

export default function Contact() {
  return (
    <>
      <div className="container">
        <div className="row my-5">
          <div className="col-md-12 text-center" data-aos="zoom-out-down">
            <h1>Contact Us</h1>
            <p>
              <Link to="/" className="btn">Home</Link> <KeyboardDoubleArrowRightIcon /> <Link to="/contact" className="btn">Contact Us</Link>
            </p>

            <div className="row shadow rounded p-4">
              <div className="col-md-6" data-aos="fade-right">
                <img
                  src="view-3d-young-school-student.jpg"
                  className="border rounded"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-md-6 text-start" data-aos="fade-left">
                <h1 className='display-5 mt-3 text-start'>Contact Us</h1>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <strong>Phone</strong>
                    <a href="tel:+919939392266" className="nav-link p-0 text-dark">
                      <PhoneEnabledIcon style={{ fontSize: "16px" }} />{" "}
                      9939392266
                    </a>
                  </li>
                  <Divider />
                  <li className="nav-item mb-2">
                    <strong>Email</strong>
                    <a href="mailto:info@nanhemunhepaathshala.com" className="nav-link p-0 text-dark">
                      <EmailIcon style={{ fontSize: "16px" }} />{" "}
                      info@nanhemunhepaathshala.com
                    </a>
                  </li>
                  <Divider />
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-dark">
                      <strong>Address</strong>
                      <a href="#" className="nav-link p-0 text-dark">
                        <LocationOnIcon style={{ fontSize: "16px" }} />{" "}
                        Branch - Patrkar Nagar, Opposite power grid, Jahanabad road, Ekangarsarai, Pincode 801301
                      </a>
                      <a href="#" className="nav-link p-0 text-dark">
                        <LocationOnIcon style={{ fontSize: "16px" }} />{" "}
                        Head Office - Aaya Nagar, Phase 2, New Delhi Pin -110047
                      </a>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="p-4 "></div>
          </div>
        </div>
      </div>
      <div className="container-fluid text-center">
        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3609.3569418911757!2d85.22552967538275!3d25.224899977693056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDEzJzI5LjYiTiA4NcKwMTMnNDEuMiJF!5e0!3m2!1sen!2sin!4v1729269274971!5m2!1sen!2sin" width="100%" height="450" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </>
  );
}
