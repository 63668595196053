import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Link } from 'react-router-dom';

export default function About() {
    return (
      <>
        <div className='container'>
            <div className='row my-5'>
                <div className='col-md-12 text-center' data-aos="zoom-out-down">
                    <h1>About</h1>
                    <Link to="/" className="btn">Home</Link> <KeyboardDoubleArrowRightIcon /> <Link to="/contact" className="btn">About</Link>

                    <div className='row p-4 shadow rounded'>
                      <div className='col-md-6' ata-aos="fade-right">
                        <img src='view-3d-young-school-1080.jpg' className="border rounded" style={{width: '100%'}}/>
                      </div>
                      <div className='col-md-6 text-start' data-aos="fade-left">
                        <h1 className='display-5 mt-3 text-start'>About Nanhe Munhe Paathshala</h1>
                        <p>Nanhe Munhe Paathshala is one of the child care and education options available to parents of young children before their child full time school and sets them up with better learning and social skills.</p>
                        <p>NMP provides a strong focus on studies by implementing phonic sound, electronic media and other innovative methods to enhance & support learning. We have digital classrooms, playground with a variety of playing equipments.</p> 
                        <p>The NMP is established to provide high quality early childhood education that develops 21st century skills through modern learning practices and create an environment where children can think, reason and become lifelong learners.</p>

                        <h1 className='display-5 mt-3 text-start'>Objectives</h1>
                        <ul>
  <li>To ensure that the step from home to school is a very happy experience for each child.</li>
  <li>To provide a learning environment that brings out the best in each child.</li>
  <li>To orient children to develop self-confidence, discipline, self-esteem, team work &amp; leadership.</li>
  <li>To provide opportunities for socialization with other children.</li>
  <li>To provide learning experiences &amp; activities to help each child attain a healthy self-concept.</li>
  <li>To provide families with security of knowing their child is in a happy &amp; nurturing environment.</li>
</ul>

                        <h1 className='display-5 mt-3 text-start'>What we offer</h1>
                        <ul>
                          <li>Instant Mobile Updates: - Track your child progress & learning through the NMP app.</li>
                          <li>Safety & Security: - We provide CCTV enabled campus for the safety & security of your child.</li>
                          <li>Child Friendly Infrastructure: - We provide a perfect blend of style, child friendly & functional infrastructure.</li>
                        </ul>

                        <h1 className='display-5 mt-3 text-start'>Activities</h1>
                        <ul>
                        <li>Art & Craft</li>
                        <li>Sports</li>
<li>Indoor games</li>
<li>Outdoor games</li>
<li>Yoga, dance and music</li>
<li>Role play</li>
<li>Special days & festival celebrations</li>
<li>Confidence building activities & stage shows.</li>
<li>Doing Puzzles, playing with blocks.</li>
<li>Painting and drawing </li>
<li>Listening to books, stories and poetry.</li>
<li>Playing, dress up, running, swinging and jumping.</li>
<li>Playing with clay, sand and water.</li>

                        </ul>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
  }
  