import './Home.css';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Slider from './Slider';
import { TextField, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

export default function Home() {
    // const notificationUrl = "http://localhost/nanhemunhepaathshala.com/api/notifications.php";
    const notificationUrl = "https://coderelisher.com/api/nmp/notifications.php";
    const contactformUrl = "https://coderelisher.com/api/nmp/contactform.php";

    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [notifications, setNotifications] = useState({});
    const [error, setError] = useState(null);

    async function fetchData(){
        await fetch(notificationUrl)
        .then(response => {
            if (!response.ok) { throw new Error('Network response was not ok'); }
            return response.json();
        })
        .then(data => {
            setNotifications(data);
            console.log(data);
        })
        .catch(error => {
            setError(error.message);
            console.log(error.message);
        });
    }

    const [response, setResponse] = useState({});
    async function sendEncData(){
        const userData = { name, mobile, email, address, message };
        fetch(contactformUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData)
        })
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            setResponse(data)
            console.log(data);
            alert(data.message)
        })
        .catch((error) => console.error(error));
    }


    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [message, setMessage] = useState('');
    const handleEnqSubmit = (event) => {
        event.preventDefault();
        console.log(`${name} ${mobile} ${email} ${address} ${message}`);
        sendEncData();
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className='container-fluid mt-3' data-aos="zoom-out-down">
                <Slider />
            </div>

            <div className='container-fluid'>

                <div className='col-md-12 text-center mt-4' style={{ backgroundImage: 'url("partner-bg.jpg")', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} data-aos="fade-up">
                    <div className='row p-4 shadow rounded'>
                        <div className='col-md-12 text-start'>
                            <h1 className='display-4 text-center'>Notification</h1>
                            <marquee onMouseOver={(e) => { try { e?.target?.stop() } catch (ex) { } }} onMouseOut={(e) => { try { e?.target?.start() } catch (ex) { } }} dangerouslySetInnerHTML={{__html: notifications.message}}></marquee>
                        </div>
                    </div>
                </div>

                <div className='row my-5'>
                    {/* <div className='col-md-12 text-center mt-0' data-aos="zoom-out-down">
                            <div className='p-4'>
                            </div>
                        </div> */}

                    {/* ABOUT */}
                    <div className='col-md-12 text-center mt-4' data-aos="fade-up">
                        <div className='row p-4 shadow rounded'>
                            <div className='col-md-6'>
                                <img src='kids_bg_small.jpg' style={{ width: '100%' }} />
                            </div>
                            <div className='col-md-6 text-start'>
                                <h1 className='display-5 mt-3 text-start'>About Nanhe Munhe Paathshala</h1>
                                <p>Nanhe Munhe Paathshala is one of the child care and education options available to parents of young children before their child full time school and sets them up with better learning and social skills.</p>
                                <p>NMP provides a strong focus on studies by implementing phonic sound, electronic media and other innovative methods to enhance & support learning. We have digital classrooms, playground with a variety of playing equipments.</p>
                                <p>The NMP is established to provide high quality early childhood education that develops 21st century skills through modern learning practices and create an environment where children can think, reason and become lifelong learners.</p>
                                <Link to="/about" className='btn'><Button variant="contained">Read more</Button></Link>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12 text-center mt-4' data-aos="fade-up">
                        <div className='row p-4 shadow rounded'>
                            <div className='col-md-6 text-start'>
                                <h1 className='display-5 mt-3 text-start'>Objectives</h1>
                                <ul>
                                    <li>To ensure that the step from home to school is a very happy experience for each child.</li>
                                    <li>To provide a learning environment that brings out the best in each child.</li>
                                    <li>To orient children to develop self-confidence, discipline, self-esteem, team work &amp; leadership.</li>
                                    <li>To provide opportunities for socialization with other children.</li>
                                    <li>To provide learning experiences &amp; activities to help each child attain a healthy self-concept.</li>
                                    <li>To provide families with security of knowing their child is in a happy &amp; nurturing environment.</li>
                                </ul>
                            </div>
                            <div className='col-md-6'>
                                <img src='kids_bg_small_2.jpg' style={{ width: '100%' }} />
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12 text-center mt-4' data-aos="fade-up">
                        <div className="row shadow rounded p-4">
                            <div className='col-md-4 mt-4'>
                                <img src='card-1.jpg' style={{ width: '100%' }} />
                            </div>
                            <div className='col-md-8 text-start'>
                                <h1 className='display-5 mt-3 text-start'>Play Group Program</h1>
                                <Box sx={{ width: '100%', typography: 'body1' }}>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange} variant="scrollable"
                                                scrollButtons
                                                allowScrollButtonsMobile aria-label="lab API tabs example">
                                                <Tab label="PRE-NURSERY" value="1" />
                                                <Tab label="NURSERY" value="2" />
                                                <Tab label="JUNIOR K.G" value="3" />
                                                <Tab label="SENIOR K.G" value="4" />
                                                <Tab label="DAY CARE" value="5" />
                                                <Tab label="CLASS I" value="6" />
                                                <Tab label="CLASS II" value="7" />
                                                <Tab label="CLASS III" value="8" />

                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">
                                            <strong>AGE 1.6 years to 2.5 years</strong><br />We provide for child teacher connect to make them feel comfortable, hence we maintain a smaller teacher student ratio.<br />Our approach ensures development of each child through the activities like play, rhymes, storytelling, arts & crafts, drama and social skills.
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <strong>AGE 2.5 years to 3.5 years</strong><br />
                                            At this stage, children's vocabulary head developed enough to express their needs and receite stories & rhymes.<br />
                                            Our approach enables a child to develop socializing, exploring, experimenting, logical thinking, physical development, language skills in a fun filled manner.
                                        </TabPanel>
                                        <TabPanel value="3">
                                            <strong>AGE 3.5 years to 4.5 years</strong><br />
                                            It is designed to help children build on their prior knowledge and experiences to form the concepts & acquire foundational skills.<br />
                                            Our approach enables the development of skills, logical reasoning, through sorting, grouping, number concepts with mathematical operations. Our learning center's provide both free and guided play, thus enhancing the child's imagination & creativity.
                                        </TabPanel>
                                        <TabPanel value="4">
                                            <strong>AGE 4.6 years to 5.5 years</strong><br />
                                            NMP develops essential skills to be independent, knowledgeable, confident and to respect self and others.<br />
                                            Our approach fosters fullness of children are -<br />
                                            Language - by providing language rich environment.<br />
                                            Science - by giving children opportunities to explore experiment & discover through hands on learning activities.<br />
                                            Maths - by the concepts of pre math, numbers, counting and mathematical operations thus creating the curiosity & zeal of exploring.<br />
                                            Life skills - through the life skill program grow for a confident transition to primary school.<br />
                                        </TabPanel>
                                        <TabPanel value="5">
                                            We create homely environment to complement mother's affection. Our trained staff understands the importance of providing a safe, enriched and secured atmosphere to children for their development.<br />
                                            Fun activities to keep children engaged and happy.<br />
                                            Ample resting place to restore their energy.<br />
                                            Regular interaction with parents to discuss the well-being of their children.<br />
                                            Classroom program / After Play Group<br />
                                        </TabPanel>
                                        <TabPanel value="6">
                                            <strong>AGE 5.5 years to 6.5 years</strong><br />
                                            Our Class I curriculum focuses on establishing a strong foundation in language, numeracy, and social skills. Our experienced teachers use innovative methods to make learning fun and interactive, ensuring that our young students develop essential skills in:
                                            <ul>
                                                <li>Alphabet recognition and basic reading</li>
                                                <li>Number sense and basic arithmetic operations</li>
                                                <li>Shape and color recognition</li>
                                                <li>Social skills, such as sharing, taking turns, and cooperation</li>
                                                <li>Fine and gross motor skills through play-based activities</li>
                                            </ul>
                                        </TabPanel>
                                        <TabPanel value="7">
                                            <strong>AGE 6.5 years to 7.5 years</strong><br />
                                            In Class II, we build upon the foundation established in Class I, gradually introducing more complex concepts and activities to challenge and engage our students. The curriculum includes:
                                            <ul>
                                                <li>Phonics and basic reading comprehension</li>
                                                <li>Basic addition and subtraction concepts</li>
                                                <li>Introducing basic shapes and patterns</li>
                                                <li>Developing social skills, such as empathy and self-awareness</li>
                                                <li>Enhancing fine and gross motor skills through arts, crafts, and physical activities</li>
                                            </ul>
                                        </TabPanel>
                                        <TabPanel value="8">
                                            <strong>AGE 7.5 years to 8.5 years</strong><br />
                                            In Class III, our students continue to develop their skills and knowledge in a more structured and challenging environment. The curriculum includes:
                                            <ul>
                                                <li>Reading comprehension and basic writing skills</li>
                                                <li>Basic multiplication and division concepts</li>
                                                <li>Introducing basic science and environmental awareness</li>
                                                <li>Developing critical thinking and problem-solving skills</li>
                                                <li>Encouraging independence, self-confidence, and teamwork</li>
                                            </ul>
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12 text-center mt-4' data-aos="fade-up">
                        <div className='row p-4 shadow rounded'>
                            <div className='col-md-12 text-start'>
                                <h1 className='display-5 mt-3 text-center'>Why Franchise with us?</h1>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header">
                                        No Royalty
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        India's First No Royalty Model Preschool Chain.
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header">
                                        Regular Yearly Training
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Regular Yearly Training at 25+ Training Locations In India
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header">
                                        Business Meet
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Yearly Business Meet with Director, NanheMunhePaathShala.
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header">
                                        Curriculum
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        International Age Appropriate Curriculum - NCERT (CBSE/ICSE/IB/SB).
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header">
                                        Support
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        24/7 Support from Director, NanheMunhePaathShala.
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header">
                                        Forum
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        Telegram App Forum Connect with All NanheMunhePaathShala Owners Across India
                                    </AccordionDetails>
                                </Accordion>

                                <div className='col-md-12 text-center'>
                                    <Link to="/franchise" className='btn mt-3'><Button variant="contained">Enquiry</Button></Link>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className='col-md-12 text-center mt-4' data-aos="fade-down" data-aos-anchor-placement="bottom-bottom">
                        <div className='p-4 shadow rounded'>
                            <h1 className='display-5'>Our Team</h1>
                            <p>Something new is coming...</p>
                        </div>
                    </div> */}

                </div>
            </div>

            <div className='container-fluid bg-light mt-3 p-5 text-center' data-aos="fade-up">
                <h1 className='text-dark'>Enquire Now</h1>
                <form onSubmit={handleEnqSubmit}>
                <TextField id="outlined-basic" label="Full Name" variant="outlined" className='w-100' color="primary" value={name} onChange={(event) => setName(event.target.value)}/>
                <TextField id="outlined-basic" label="Mobile Number" variant="outlined" className='w-100 mt-2' color="primary" value={mobile} onChange={(event) => setMobile(event.target.value)}/>
                <TextField id="outlined-basic" label="Email" variant="outlined" className='w-100 mt-2' color="primary" value={email} onChange={(event) => setEmail(event.target.value)}/>
                <TextField id="outlined-basic" label="Address" variant="outlined" className='w-100 mt-2' color="primary" value={address} onChange={(event) => setAddress(event.target.value)}/>
                <TextField id="outlined-basic" label="Message (Optional)" variant="outlined" className='w-100 mt-2' multiline minRows={4} color="primary" value={message} onChange={(event) => setMessage(event.target.value)}/>
                <Button className='mt-2' variant="outlined" size="large" color='primary' type='submit'>Submit</Button>
                </form>
            </div>
        </>
    );
}
